/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Hidden, Link, List, ListSubheader, Typography, makeStyles } from '@material-ui/core';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import navConfig from './NavItems';
import { useAdminMode } from '../../../hooks/useAdminMode';

// get the different navigation configurations
const { navConfigAdmin } = navConfig;
const { navConfigUser } = navConfig;

function renderNavItems({ items, hide, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            hide,
            ...rest
          }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, hide, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        shortTitle={item.shortTitle}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          shouldHide: hide
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        shortTitle={item.shortTitle}
        shouldHide={hide}
      />
    );
  }

  return acc;
}

const drawerWidth = 256;

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: drawerWidth
  },
  contentBoxSmall: {
    paddingLeft: 0,
    paddingRight: 0
  },
  subheader: {
    paddingLeft: 16,
    textTransform: 'uppercase'
  },
  slimDrawer: {
    top: 74,
    overflowX: 'hidden',
    height: 'calc(100% - 74px)',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  avatar: {
    cursor: 'pointer',
    width: 120,
    height: 120,
    border: `5px solid ${theme.palette.third.main}`,
  },
  drawerOpen: {
    top: 74,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  scrollbar: {
    height:'100%',
    display:'flex', 
    flexDirection:'column',
    boxShadow: '0px -18px 30px rgba(219, 203, 189, 0.5), 0px -18px 30px rgba(255, 255, 255, 0.5)',
    backgroundColor: theme.palette.background.navbar
  },
  drawerClose: {
    top: 74,
    overflowX: 'hidden',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  }
}));

function NavBar({ openMobile, openMenu, onMobileClose }) {
  const { isAdminModeEnabled } = useAdminMode();
  const classes = useStyles({ adminMode: isAdminModeEnabled });
  const location = useLocation();
  const { user } = useSelector(state => state.account);
  const { t } = useTranslation('navigation');

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box className={classes.scrollbar}>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display={openMenu ? 'flex' : 'none'} justifyContent="center">
            <RouterLink to="/app/overview">
              <Avatar alt="Authority" variant="circle" className={classes.avatar} src={user.authority.logo} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center" display={openMenu ? 'flex' : 'none'} justifyContent="center">
            <Link component={RouterLink} to="/app/overview" variant="h5" color="textPrimary" underline="none">
              {user.authority.description}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.authority.federation.logo}
            </Typography>
          </Box>
        </Box>
        {openMenu && <Divider />}
        <Box p={0} className={clsx({ [classes.contentBoxSmall]: !openMenu })}>
          {isAdminModeEnabled
            ? navConfigAdmin.map(config => (
                <List
                  key={config.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky className={classes.subheader}>
                      {openMenu ? t(config.subheader) : null}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: config.items,
                    pathname: location.pathname,
                    hide: openMenu
                  })}
                </List>
              ))
            : navConfigUser.map(config => (
                <List
                  key={config.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky className={classes.subheader}>
                      {openMenu ? t(config.subheader) : null}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: config.items,
                    pathname: location.pathname,
                    hide: openMenu
                  })}
                </List>
              ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={openMenu ? { paper: classes.drawerOpen } : { paper: classes.drawerClose }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  openMenu: PropTypes.bool
};

export default NavBar;
