/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  ADD_USER,
  LOAD_USERS,
  LOAD_SINGLE_USER,
  UPDATE_USER_AVATAR
} from 'src/actions/userActions';

const initialState = {
  isLoaded: false,
  users: null, // all users
  isUserLoaded: false,
  user: null, // single user
  userUpdatedAt: new Date()
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER: {
      const { user } = action.payload;

      return produce(state, draft => {
        draft.user = user;
        draft.userUpdatedAt = new Date();
        draft.isUserLoaded = true;
      });
    }
    case LOAD_USERS: {
      const { users } = action.payload;

      return produce(state, draft => {
        draft.users = users;
        draft.isLoaded = true;
        draft.isUserLoaded = false;
      });
    }
    case LOAD_SINGLE_USER: {
      const { user } = action.payload;

      return produce(state, draft => {
        draft.user = user;
        draft.userUpdatedAt = new Date();
        draft.isUserLoaded = true;
      });
    }
    case UPDATE_USER_AVATAR: {
      const { user } = action.payload;

      return produce(state, draft => {
        draft.user = user;
        draft.userUpdatedAt = new Date();
        draft.isUserLoaded = true;
      });
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
