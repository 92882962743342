import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import notificationsReducer from './notificationsReducer';
import kanbanReducer from './kanbanReducer';
import dossierReducer from './dossierReducer';
import personReducer from './personReducer';
import healthReducer from './healthReducer';
import { dossierStructureReducer } from './dossierStructuresReducer';
import userReducer from './userReducer';
import authorityReducer from './authorityReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  notifications: notificationsReducer,
  kanban: kanbanReducer,
  dossier: dossierReducer,
  dossierStructure: dossierStructureReducer,
  person: personReducer,
  health: healthReducer,
  form: formReducer,
  user: userReducer,
  authority: authorityReducer
});

export default rootReducer;
