import userService from 'src/services/userService';

export const ADD_USER = '@user/add-user';
export const DELETE_USER = '@user/delete-user';
export const DELETE_USER_FAILURE = '@user/delete-user-failure';
export const ADD_USER_FAILURE = '@user/add-userfailure';
export const LOAD_SINGLE_USER = '@user/load-single-user';
export const LOAD_SINGLE_USER_FAILURE = '@user/load-single-user-failure';
export const LOAD_USERS = '@user/load-users';
export const LOAD_USERS_FAILURE = '@user/load-failure';
export const UPDATE_USER_AVATAR = '@user/update-user-avatar';
export const UPDATE_USER_AVATAR_FAILURE = '@user/update-user-avatar-failure';

export function addUser(values) {
  return async dispatch => {
    try {
      const user = await userService.addUser(values);
      dispatch({
        type: ADD_USER,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: ADD_USER_FAILURE });
      throw error;
    }
  };
}

export function loadUsers() {
  return async dispatch => {
    try {
      const users = await userService.loadUsers();
      dispatch({
        type: LOAD_USERS,
        payload: {
          users
        }
      });
    } catch (error) {
      dispatch({ type: LOAD_USERS_FAILURE });
      throw error;
    }
  };
}

export function loadSingleUser(userId) {
  return async dispatch => {
    try {
      const user = await userService.loadSingleUser(userId);
      dispatch({
        type: LOAD_SINGLE_USER,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: LOAD_SINGLE_USER_FAILURE });
      throw error;
    }
  };
}

export function deleteUser(userId) {
  return async dispatch => {
    try {
      await userService.deleteUser(userId);
      dispatch({
        type: DELETE_USER
      });
    } catch (error) {
      dispatch({ type: DELETE_USER_FAILURE });
      throw error;
    }
  };
}

export function updateUserAvatar(userId, data) {
  return async dispatch => {
    try {
      const user = await userService.updateAvatar(userId, data);
      dispatch({
        type: UPDATE_USER_AVATAR,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: UPDATE_USER_AVATAR_FAILURE });
      throw error;
    }
  };
}
