import axios from 'src/utils/axios';

class HealthService {
  loadHealthStatus = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/healthcheck')
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
}

const healthService = new HealthService();

export default healthService;
