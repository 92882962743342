import axios from 'src/utils/axios';

class DossierStructureService {
  getAll = authorityId =>
    new Promise((resolve, reject) => {
      axios
        .get(`/api/administration/authorities/${authorityId}/dossierstructures`)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  get = (authorityId, dossierStructureId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`/api/administration/authorities/${authorityId}/dossierstructures/${dossierStructureId}`)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  create = (authorityId, dossierStructure) =>
    new Promise((resolve, reject) => {
      axios
        .post(`/api/administration/authorities/${authorityId}/dossierstructures/`, dossierStructure)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  update = (authorityId, dossierStructure) =>
    new Promise((resolve, reject) => {
      const url = `/api/administration/authorities/${authorityId}/dossierstructures/${dossierStructure._id}`;

      axios
        .put(url, dossierStructure)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
}

export const dossierStructureService = new DossierStructureService();
