import { dossierStructureService } from '../services/dossierStructureService';

const namespace = '@dossier-structure';

export const Actions = {
  setLoading: `${namespace}/set-loading`,
  getFirst: `${namespace}/get-first`,
  getFirstFailure: `${namespace}/get-first-failure`,
  getAll: `${namespace}/get-all`,
  getAllFailure: `${namespace}/get-all-failure`,
  setCurrent: `${namespace}/set-current`,
  setCurrentFailure: `${namespace}/set-current-failure`,
  unsetCurrent: `${namespace}/unset-current`,
  unsetCurrentFailure: `${namespace}/unset-current-failure`,
  create: `${namespace}/create`,
  createFailure: `${namespace}/create-failure`,
  update: `${namespace}/update`,
  updateFailure: `${namespace}/update-failure`
};

export const ActionCreators = {
  getFirst(authorityId) {
    return async dispatch => {
      try {
        dispatch({
          type: Actions.setLoading,
          payload: true
        });

        const dossierStructures = await dossierStructureService.getAll(authorityId);
        dispatch({
          type: Actions.getFirst,
          payload: { dossierStructure: dossierStructures[0] }
        });
      } catch (error) {
        dispatch({ type: Actions.getFirstFailure });
        dispatch({
          type: Actions.setLoading,
          payload: false
        });
        throw error;
      }
    };
  },

  unsetCurrent() {
    return async dispatch => {
      try {
        dispatch({
          type: Actions.setLoading,
          payload: true
        });

        dispatch({ type: Actions.unsetCurrent });
      } catch (error) {
        dispatch({ type: Actions.unsetCurrentFailure });
        dispatch({
          type: Actions.setLoading,
          payload: false
        });
        throw error;
      }
    };
  },

  setCurrent(dossierStructure) {
    return async dispatch => {
      try {
        dispatch({
          type: Actions.setLoading,
          payload: true
        });

        dispatch({
          type: Actions.setCurrent,
          payload: { dossierStructure }
        });
      } catch (error) {
        dispatch({ type: Actions.setCurrentFailure });
        dispatch({
          type: Actions.setLoading,
          payload: false
        });
        throw error;
      }
    };
  },

  getAll(authorityId) {
    return async dispatch => {
      try {
        dispatch({
          type: Actions.setLoading,
          payload: true
        });

        const dossierStructures = await dossierStructureService.getAll(authorityId);
        dispatch({
          type: Actions.getAll,
          payload: { dossierStructures }
        });
      } catch (error) {
        dispatch({ type: Actions.getAllFailure });
        dispatch({
          type: Actions.setLoading,
          payload: false
        });
        throw error;
      }
    };
  },

  create(authorityId, dossierStructure) {
    return async dispatch => {
      try {
        dispatch({
          type: Actions.setLoading,
          payload: true
        });

        const newDossierStructure = await dossierStructureService.create(authorityId, dossierStructure);

        dispatch({
          type: Actions.create,
          payload: { dossierStructure: newDossierStructure }
        });
      } catch (error) {
        dispatch({ type: Actions.createFailure });
        dispatch({
          type: Actions.setLoading,
          payload: false
        });
        throw error;
      }
    };
  },

  update(authorityId, dossierStructure) {
    return async dispatch => {
      try {
        dispatch({
          type: Actions.setLoading,
          payload: true
        });

        const newDossierStructure = await dossierStructureService.update(authorityId, dossierStructure);

        dispatch({
          type: Actions.update,
          payload: { dossierStructure: newDossierStructure }
        });
      } catch (error) {
        dispatch({ type: Actions.updateFailure });
        dispatch({
          type: Actions.setLoading,
          payload: false
        });
        throw error;
      }
    };
  }
};
