import { get } from 'lodash';
import { useSelector } from 'react-redux';

import useSettings from './useSettings';

export function useAdminMode() {
  const settings = useSettings();
  const user = useSelector(state => get(state, 'account.user'));

  const isUserAdmin = user && Array.isArray(user.roles) && user.roles.includes('Administrator');
  const isAdminModeEnabled = get(settings, 'settings.mode') === 'admin';

  return {
    isUserAdmin,
    isAdminModeEnabled
  };
}
