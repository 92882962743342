import { API_BASE_URL, FILE_SIZE_LIMIT_PIC, FILE_SIZE_LIMIT_PDF } from '../config';

export function avatarUrl(userId, original = false, cacheBuster = null) {
  const url = new URL(`/api/users/${userId}/avatar`, API_BASE_URL);
  if (original) {
    url.searchParams.set('original', 'true');
  }
  if (cacheBuster) {
    url.searchParams.set('cacheBuster', cacheBuster);
  }

  return url.toString();
}

export function isValidImageFileSize(sizeInBytes) {
  return sizeInBytes <= 1024 * 1024 * FILE_SIZE_LIMIT_PIC;
}

export function isValidDocumentFileSize(sizeInBytes) {
  return sizeInBytes <= 1024 * 1024 * FILE_SIZE_LIMIT_PDF;
}
