import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Collapse, ListItem, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: theme.palette.third.main,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    strokeWidth: '3px'
  },
  iconNoPadding: {
    display: 'unset',
    alignItems: 'center',
    marginRight: 0
  },
  title: {
    marginRight: 'auto',
    whiteSpace: 'normal'
  },
  shortTitle: {
    marginRight: 0,
    fontSize: 'smaller',
    fontWeight: 500,
    whiteSpace: 'normal'
  },
  hidden: {
    display: 'none'
  },
  iconOverText: {
    display: 'block',
    paddingLeft: 0,
    paddingRight: 0
  },
  active: {
    color: theme.palette.third.main,
    backgroundColor: theme.palette.background.navbarselected,
    borderRadius: '0px',
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.third.main
    }
  }
}));

function NavItem({ title, shortTitle, href, depth, children, icon: Icon, className, open: openProp, info: Info, shouldHide, ...rest }) {
  const classes = useStyles();
  const { t } = useTranslation('navigation');
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  let paddingLeft = 16 + 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  // TODO: extract actual component:
  const shortTitleComponent = <div className={clsx(classes.shortTitle, { [classes.hidden]: shouldHide })}>{t(shortTitle)}</div>;

  if (children) {
    return (
      <ListItem className={clsx(classes.item, className)} disableGutters key={title} {...rest}>
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={clsx(classes.title, { [classes.hidden]: !shouldHide })}>{t(title)}</span>
          {open ? <ExpandLessIcon size="small" color="inherit" /> : <ExpandMoreIcon size="small" color="inherit" />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem className={clsx(classes.itemLeaf, className)} disableGutters key={title} {...rest}>
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`, {
          [classes.iconOverText]: !shouldHide
        })}
        component={RouterLink}
        exact
        style={shouldHide ? style : null}
        to={href}
      >
        {Icon && <Icon className={shouldHide ? classes.icon : classes.iconNoPadding} size="20" />}
        {shortTitleComponent}
        <span className={clsx(classes.title, { [classes.hidden]: !shouldHide })}>{t(title)}</span>
        {Info && shouldHide && <Info className={classes.info} />}
      </Button>
    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  shortTitle: PropTypes.string,
  shouldHide: PropTypes.bool.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
