import axios from 'src/utils/axios';

class PersonService {
  addPerson = data =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/persons', data)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  loadPersons = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/persons')
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  loadSinglePerson = personId =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/persons/' + personId)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
}

const personService = new PersonService();

export default personService;
