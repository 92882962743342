/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOAD_HEALTH_STATUS,
  LOAD_HEALTH_STATUS_FAILURE
} from 'src/actions/healthActions';

const initialState = {
  isLoaded: false,
  healthStatus: null
};

const healthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_HEALTH_STATUS: {
      const { healthStatus } = action.payload;

      return produce(state, draft => {
        draft.healthStatus = healthStatus;
        draft.isLoaded = true;
      });
    }
    case LOAD_HEALTH_STATUS_FAILURE: {
      return produce(state, draft => {
        draft.healthStatus = null;
        draft.isLoaded = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default healthReducer;
