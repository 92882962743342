/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/_old/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/app/dossier" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/_old/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: lazy(() => import('src/views/base/overview/OverviewView'))
      },
      {
        exact: true,
        path: '/app/overview',
        component: lazy(() => import('src/views/base/overview/OverviewView'))
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/base/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/dossier',
        component: lazy(() => import('src/views/dossier/DossierMyView'))
      },
      {
        exact: true,
        path: '/app/add_dossier',
        component: lazy(() => import('src/views/dossier/AddDossierView'))
      },
      {
        exact: true,
        path: '/app/add_person',
        component: lazy(() => import('src/views/person/AddPersonView'))
      },
      {
        exact: true,
        path: '/app/person',
        component: lazy(() => import('src/views/person/PersonView'))
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('src/views/DashboardView'))
      },
      {
        exact: true,
        path: '/app/management/configuration',
        component: lazy(() => import('src/views/management/ConfigurationView'))
      },
      {
        exact: true,
        path: '/app/dossier2',
        component: lazy(() => import('src/views/dossier/DossierAllView'))
      },
      {
        exact: true,
        path: '/app/dossierplan',
        component: lazy(() => import('src/views/DossierStructureView'))
      },
      {
        path: '/app/dossier2/:id',
        exact: true,
        component: lazy(() => import('src/views/dossier/DossierDetailView'))
      },
      {
        path: '/app/dossier/:id',
        exact: true,
        component: lazy(() => import('src/views/dossier/DossierDetailView'))
      },
      {
        path: '/app/dossier/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/dossier/DossierDetailView'))
      },
      {
        path: '/app/dossier2/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/dossier/DossierDetailView'))
      },
      {
        exact: true,
        path: '/app/management/authorities',
        component: lazy(() => import('src/views/management/AuthorityView'))
      },
      {
        exact: true,
        path: '/app/management/federations',
        component: lazy(() => import('src/views/management/FederationView'))
      },
      {
        exact: true,
        path: '/app/management/users',
        component: lazy(() => import('src/views/management/UserManagementView'))
      },
      {
        exact: true,
        path: '/app/management/users/:id',
        component: lazy(() => import('src/views/management/UserManagementView/UserManagementDetailView'))
      },
      {
        exact: true,
        path: '/app/management/users/:id/:tab',
        component: lazy(() => import('src/views/management/UserManagementView/UserManagementDetailView'))
      },
      {
        exact: true,
        path: '/app/management/add_user',
        component: lazy(() => import('src/views/management/AddUserView'))
      },
      {
        exact: true,
        path: '/app/management/rights',
        component: lazy(() => import('src/views/management/RightsView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/_old/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/_old/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/_old/pages/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
