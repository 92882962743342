import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, Hidden, IconButton, Toolbar, makeStyles, SvgIcon } from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Search from './Search';
import Settings from './Settings';
import { useAdminMode } from '../../../hooks/useAdminMode';

const useStyles = makeStyles(theme => {
  let rootStyles = {};

  switch (theme.themeName) {
    case THEMES.LIGHT:
      rootStyles = {
        boxShadow: '-18px 0px 30px rgba(219, 203, 189, 0.5)',
        backgroundColor: ({ adminMode }) => {
          return adminMode ? theme.palette.background.admin : theme.palette.background.default;
        }
      };
      break;
    case THEMES.ONE_DARK:
      rootStyles = {
        backgroundColor: ({ adminMode }) => {
          return adminMode ? theme.palette.background.admin : theme.palette.background.default;
        }
      };
      break;
    case THEMES.UNICORN:
      rootStyles = {
        backgroundColor: ({ adminMode }) => {
          return adminMode ? theme.palette.background.admin : theme.palette.background.default;
        }
      };
      break;
    default:
      break;
  }

  return {
    root: {
      ...rootStyles,
      zIndex: theme.zIndex.drawer + 100
    },
    menuButton: {
      marginRight: 36
    },
    toolbar: {
      minHeight: 74,
      paddingLeft: 14
    },
    icon: {
      stroke: theme.palette.third.main,
      strokeWidth: '3px'
    }
  };
});

function TopBar({ className, onMobileNavOpen, onMenuOpen, ...rest }) {
  const { isAdminModeEnabled } = useAdminMode();
  const classes = useStyles({ adminMode: isAdminModeEnabled });

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton className={classes.menuButton} color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon className={classes.icon}/>
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <IconButton className={clsx(classes.menuButton)} color="inherit" onClick={onMenuOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon className={classes.icon}/>
            </SvgIcon>
          </IconButton>
          <Box ml={2} flexGrow={1} />
          <RouterLink to="/">
            <Logo height="51px"/>
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Search />
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  onMenuOpen: PropTypes.func
};

export default TopBar;
