import healthService from 'src/services/healthService';

export const LOAD_HEALTH_STATUS = '@health/load-health-status';
export const LOAD_HEALTH_STATUS_FAILURE = '@health/load-health-status-failure';

export function loadHealthStatus() {
  return async dispatch => {
    try {
      const healthStatus = await healthService.loadHealthStatus();
      dispatch({
        type: LOAD_HEALTH_STATUS,
        payload: {
          healthStatus
        }
      });
    } catch (error) {
      dispatch({ type: LOAD_HEALTH_STATUS_FAILURE });
      throw error;
    }
  };
}
