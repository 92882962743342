/* eslint-disable no-param-reassign */
import produce from 'immer';
import { ADD_PERSON, LOAD_PERSONS, LOAD_SINGLE_PERSON } from 'src/actions/personActions';

const initialState = {
  isLoaded: false,
  persons: null, // all persons
  isPersonLoaded: false,
  person: null, // single person
  personUpdatedAt: new Date()
};

const personReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PERSON: {
      const { person } = action.payload;

      return produce(state, draft => {
        draft.person = person;
        draft.isLoaded = false;
        draft.isPersonLoaded = false;
      });
    }
    case LOAD_PERSONS: {
      const { persons } = action.payload;

      return produce(state, draft => {
        draft.persons = persons;
        draft.isLoaded = true;
        draft.isPersonLoaded = false;
      });
    }
    case LOAD_SINGLE_PERSON: {
      const { person } = action.payload;

      return produce(state, draft => {
        draft.person = person;
        draft.personUpdatedAt = new Date();
        draft.isPersonLoaded = true;
      });
    }
    default: {
      return state;
    }
  }
};

export default personReducer;
