/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Square as CalendarIcon,
  Activity as ActivityIcon,
  Clipboard as FeatherIcon,
  Hash as HashIcon,
  Globe as GlobeIcon,
  Shield as ShieldIcon
} from 'react-feather';

import HomeIcon from '@material-ui/icons/Home';
import FolderIcon from '@material-ui/icons/Folder';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

const navConfigUser = [
  {
    subheader: 'sections.actions',
    items: [
      {
        title: 'actions.add_dossier',
        shortTitle: 'actions.add_dossier_short',
        icon: CreateNewFolderIcon,
        href: '/app/add_dossier'
      },
      {
        title: 'actions.add_person',
        shortTitle: 'actions.add_person_short',
        icon: PersonAddIcon,
        href: '/app/add_person'
      }
    ]
  },
  {
    subheader: 'sections.navigation',
    items: [
      {
        title: 'user.dashboard',
        shortTitle: 'user.dashboard_short',
        icon: HomeIcon,
        href: '/app/overview'
      },
      {
        title: 'user.my_dossiers',
        shortTitle: 'user.my_dossiers_short',
        icon: FolderIcon,
        href: '/app/dossier'
      },
      {
        title: 'user.all_dossiers',
        shortTitle: 'user.all_dossiers_short',
        icon: FolderIcon,
        href: '/app/dossier2'
      },
      {
        title: 'user.people',
        shortTitle: 'user.people_short',
        icon: PeopleAltIcon,
        href: '/app/person'
      },
      {
        title: 'user.deadlines',
        shortTitle: 'user.deadlines_short',
        icon: InsertInvitationIcon,
        href: '/app/calendar'
      }
    ]
  }
];

// REVIEW: RW, 2020-04-28: add a title in proper way
const navConfigAdmin = [
  {
    subheader: 'sections.actions',
    items: [
      {
        title: 'actions.add_user',
        shortTitle: 'actions.add_user_short',
        icon: PersonAddIcon,
        href: '/app/management/add_user'
      }
    ]
  },
  {
    subheader: 'sections.navigation',
    items: [
      {
        title: 'administration.dashboard',
        shortTitle: 'administration.dashboard_short',
        icon: ActivityIcon,
        href: '/app/dashboard'
      },
      {
        title: 'administration.dossier_plan',
        shortTitle: 'administration.dossier_plan_short',
        icon: FeatherIcon,
        href: '/app/dossierplan'
      },
      {
        title: 'administration.user',
        shortTitle: 'administration.user_short',
        icon: PeopleAltIcon,
        href: '/app/management/users'
      },
      {
        title: 'administration.authorities',
        shortTitle: 'administration.authorities_short',
        icon: CalendarIcon,
        href: '/app/management/authorities'
      },
      {
        title: 'administration.federations',
        shortTitle: 'administration.federations_short',
        icon: GlobeIcon,
        href: '/app/management/federations'
      },
      {
        title: 'administration.configuration',
        shortTitle: 'administration.configuration_short',
        icon: HashIcon,
        href: '/app/management/configuration'
      },
      {
        title: 'administration.security',
        shortTitle: 'administration.security_short',
        icon: ShieldIcon,
        href: '/app/management/rights'
      }
    ]
  }
];

export default {
  navConfigUser,
  navConfigAdmin
};
