import { dossierService } from '../services/dossierService';
import { dossierStructureService } from '../services/dossierStructureService';

export const LOAD_SINGLE_DOSSIER = '@dossier/load-single-dossier';
export const ADD_DOSSIER = '@dossier/add-dossier';
export const ADD_DOSSIER_FAILURE = '@dossier/add-dossier-failure';
export const DELETE_DOSSIER = '@dossier/delete-dossier';
export const UPDATE_DOSSIER = '@dossier/update-dossier';
export const UPDATE_DOSSIER_STRUCTURE = '@dossier/update-dossier-structure';
export const UPDATE_DOSSIER_STRUCTURE_FAILURE = '@dossier/update-dossier-structure-failure';
export const LOAD_DOSSIER_FAILURE = '@dossier/load-failure';
export const SELECT_DOSSIER = '@dossier/select-dossier';
export const SELECT_DOSSIER_FILE = '@dossier/select-dossier-file';
export const ADD_DOSSIER_FILE = '@dossier/add-dossier-file';
export const ADD_DOSSIER_FILE_DOCUMENT = '@dossier/add-dossier-file-document';
export const ADD_DOSSIER_FILE_DOCUMENT_FAILURE = '@dossier/add-dossier-file-document-failure';
export const DELETE_DOSSIER_FILE = '@dossier/delete-dossier-file';
export const UPDATE_DOSSIER_FILE = '@dossier/update-dossier-file';
export const DELETE_DOSSIER_FILE_DOCUMENT = '@dossier/delete-dossier-file-document';
export const UPLOAD_DOCUMENT_TO_DOSSIER = '@dossier/upload-document-to-dossier';
export const ADD_EPAGINA_TO_PDF = '@dossier/add-epagina-to-pdf';
export const PRINT_DOSSIER_FILE = '@dossier/print-dossier';
export const PRINT_DOSSIER_FILE_FAILURE = '@dossier/print-dossier-failure';

export function addDossier(values) {
  return async dispatch => {
    try {
      const dossier = await dossierService.create(values);
      dispatch({
        type: ADD_DOSSIER,
        payload: {
          dossier
        }
      });
    } catch (error) {
      dispatch({ type: ADD_DOSSIER_FAILURE });
      throw error;
    }
  };
}

export function loadSingleDossier(dossierId) {
  return async dispatch => {
    try {
      const dossier = await dossierService.get(dossierId);
      dispatch({
        type: LOAD_SINGLE_DOSSIER,
        payload: {
          dossier
        }
      });
    } catch (error) {
      dispatch({ type: LOAD_DOSSIER_FAILURE });
      throw error;
    }
  };
}

export function updateDossierStructure(authorityId, dossierStructure) {
  return async dispatch => {
    try {
      const newDossierStructure = await dossierStructureService.update(authorityId, dossierStructure);

      dispatch({
        type: UPDATE_DOSSIER_STRUCTURE,
        payload: { dossierStructure: newDossierStructure }
      });
    } catch (error) {
      dispatch({ type: UPDATE_DOSSIER_STRUCTURE_FAILURE });
      throw error;
    }
  };
}

export function uploadDocumentToDossier(dossierId, dossierFileId, documents) {
  return async dispatch => {
    try {
      const dossier = await dossierService.uploadDocumentToDossier(dossierId, dossierFileId, documents);
      dispatch({
        type: UPLOAD_DOCUMENT_TO_DOSSIER,
        payload: {
          dossier
        }
      });
    } catch (error) {
      dispatch({ type: LOAD_DOSSIER_FAILURE });
      throw error;
    }
  };
}

export function addDossierFile(dossierId, data) {
  return async dispatch => {
    try {
      const dossier = await dossierService.addDossierFile(dossierId, data);
      dispatch({
        type: ADD_DOSSIER_FILE,
        payload: {
          dossier
        }
      });
    } catch (error) {
      dispatch({ type: LOAD_DOSSIER_FAILURE });
      throw error;
    }
  };
}

export function addDossierFileDocument(dossierId, dossierFileId, document) {
  return async dispatch => {
    try {
      const dossier = await dossierService.addDossierFileDocument(dossierId, dossierFileId, document);
      dispatch({
        type: ADD_DOSSIER_FILE_DOCUMENT,
        payload: {
          dossier
        }
      });
    } catch (error) {
      dispatch({ type: ADD_DOSSIER_FILE_DOCUMENT_FAILURE });
      throw error;
    }
  };
}

export function deleteDossierFile(dossierId, dossierFileId) {
  return async dispatch => {
    try {
      const dossier = await dossierService.deleteDossierFile(dossierId, dossierFileId);
      dispatch({
        type: DELETE_DOSSIER_FILE,
        payload: {
          dossier
        }
      });
    } catch (error) {
      dispatch({ type: LOAD_DOSSIER_FAILURE });
      throw error;
    }
  };
}

export function deleteDossierFileDocument(dossierId, dossierFileId) {
  return async dispatch => {
    try {
      const dossier = await dossierService.deleteDossierFileDocument(dossierId, dossierFileId);
      dispatch({
        type: DELETE_DOSSIER_FILE_DOCUMENT,
        payload: {
          dossier
        }
      });
    } catch (error) {
      dispatch({ type: LOAD_DOSSIER_FAILURE });
      throw error;
    }
  };
}

export function updateDossierFile(dossierId, dossierFileId, data) {
  return async dispatch => {
    try {
      const dossier = await dossierService.updateDossierFile(dossierId, dossierFileId, data);
      dispatch({
        type: UPDATE_DOSSIER_FILE,
        payload: {
          dossier
        }
      });
    } catch (error) {
      dispatch({ type: LOAD_DOSSIER_FAILURE });
      throw error;
    }
  };
}

export function addEPaginaToPDF(dossierId, dossierFileId) {
  return async dispatch => {
    try {
      const dossier = await dossierService.addEPagina(dossierId, dossierFileId);
      dispatch({
        type: ADD_EPAGINA_TO_PDF,
        payload: {
          dossier
        }
      });
    } catch (error) {
      dispatch({ type: LOAD_DOSSIER_FAILURE });
      throw error;
    }
  };
}

export function printDossierFile(dossierId, dossierFileId) {
  return async dispatch => {
    try {
      await dossierService.dossierFilePrint(dossierId, dossierFileId);

      dispatch({
        type: PRINT_DOSSIER_FILE
      });
    } catch (error) {
      dispatch({ type: PRINT_DOSSIER_FILE_FAILURE });
      throw error;
    }
  };
}
