import personService from 'src/services/personService';

export const ADD_PERSON = '@person/add-person';
export const ADD_PERSON_FAILURE = '@person/add-person-failure';
export const LOAD_PERSONS = '@person/load-person';
export const LOAD_SINGLE_PERSON = '@person/load-single-person';
export const DELETE_PERSON = '@person/delete-person';
export const UPDATE_PERSON = '@person/update-person';
export const LOAD_PERSON_FAILURE = '@person/load-failure';

export function addPerson(values) {
  return async dispatch => {
    try {
      const person = await personService.addPerson(values);
      dispatch({
        type: ADD_PERSON,
        payload: {
          person
        }
      });
    } catch (error) {
      dispatch({ type: ADD_PERSON_FAILURE });
      throw error;
    }
  };
}

export function loadPersons() {
  return async dispatch => {
    try {
      const persons = await personService.loadPersons();
      dispatch({
        type: LOAD_PERSONS,
        payload: {
          persons
        }
      });
    } catch (error) {
      dispatch({ type: LOAD_PERSON_FAILURE });
      throw error;
    }
  };
}

export function loadSinglePerson(personId) {
  return async dispatch => {
    try {
      const person = await personService.loadSinglePerson(personId);
      dispatch({
        type: LOAD_SINGLE_PERSON,
        payload: {
          person
        }
      });
    } catch (error) {
      dispatch({ type: LOAD_PERSON_FAILURE });
      throw error;
    }
  };
}
