import axios from 'src/utils/axios';
import printJS from 'print-js';

class DossierService {
  getAll = ({ page = 0, pageSize = 10 } = {}) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/dossiers', {
          params: {
            page,
            pageSize
          }
        })
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  get = dossierId =>
    new Promise((resolve, reject) => {
      axios
        .get(`/api/dossiers/${dossierId}`)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  uploadDocumentToDossier = (dossierId, dossierFileId, documents) =>
    new Promise((resolve, reject) => {
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const fd = new FormData();
      fd.append('document', documents[0]);

      axios
        .post(`/api/dossiers/${dossierId}/dossierfiles/${dossierFileId}/document`, fd, config)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  addDossierFile = (dossierId, data) =>
    new Promise((resolve, reject) => {
      axios
        .post(`/api/dossiers/${dossierId}/dossierfiles`, data)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  addDossierFileDocument = (dossierId, dossierFileId, document) =>
    new Promise((resolve, reject) => {
      axios
        .post(`/api/dossiers/${dossierId}/dossierfiles/${dossierFileId}/document`, document)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  create = data =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/dossiers', data)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  delete = dossierId =>
    new Promise((resolve, reject) => {
      axios
        .delete(`/api/dossiers/${dossierId}`)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  deleteDossierFile = (dossierId, dossierFileId) =>
    new Promise((resolve, reject) => {
      axios
        .delete(`/api/dossiers/${dossierId}/dossierfiles/${dossierFileId}`)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  deleteDossierFileDocument = (dossierId, dossierFileId) =>
    new Promise((resolve, reject) => {
      axios
        .delete(`/api/dossiers/${dossierId}/dossierfiles/${dossierFileId}/document`)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  updateDossierFile = (dossierId, dossierFileId, data) =>
    new Promise((resolve, reject) => {
      axios
        .put(`/api/dossiers/${dossierId}/dossierfiles/${dossierFileId}`, data)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  addEPagina = (dossierId, dossierFileId) =>
    new Promise((resolve, reject) => {
      axios
        .post(`/api/epagina/${dossierId}/${dossierFileId}`)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  dossierIndexDownload = dossierId =>
    new Promise((resolve, reject) => {
      axios
        .get(`/api/dossiers/${dossierId}/dossierindex.pdf`, { responseType: 'blob' })
        .then(response => {
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', `dossierindex-${dossierId}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });

  dossierFilePrint = (dossierId, dossierFileId) => {
    const documentUrl = `/api/dossiers/${dossierId}/dossierfiles/${dossierFileId}/document`;

    return new Promise((resolve, reject) => {
      axios
        .get(documentUrl, { responseType: 'arraybuffer' })
        .then(response => {
          const document = Buffer.from(response.data, 'binary').toString('base64');
          printJS({
            printable: document,
            type: 'pdf',
            base64: true
          });

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

export const dossierService = new DossierService();
