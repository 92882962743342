/* eslint-disable no-param-reassign */
import produce from 'immer';

import { Actions } from 'src/actions/dossierStructureActions';

const initialState = {
  isLoading: true,
  dossierStructures: [],
  currentDossierStructure: null
};

export const dossierStructureReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.setLoading: {
      const isLoading = action.payload;

      return produce(state, draft => {
        draft.isLoading = isLoading;
      });
    }

    case Actions.getFirst: {
      const { dossierStructure } = action.payload;

      return produce(state, draft => {
        draft.currentDossierStructure = dossierStructure;
        draft.isLoading = false;
      });
    }

    case Actions.getAll: {
      const { dossierStructures } = action.payload;

      return produce(state, draft => {
        draft.dossierStructures = dossierStructures;
        draft.isLoading = false;
      });
    }

    case Actions.setCurrent: {
      const { dossierStructure } = action.payload;

      return produce(state, draft => {
        draft.currentDossierStructure = dossierStructure;
        draft.isLoading = false;
      });
    }

    case Actions.unsetCurrent: {
      return produce(state, draft => {
        draft.currentDossierStructure = undefined;
        draft.isLoading = false;
      });
    }

    case Actions.create: {
      const { dossierStructure } = action.payload;

      return produce(state, draft => {
        draft.dossierStructures.push(dossierStructure);
        draft.currentDossierStructure = dossierStructure;
        draft.isLoading = false;
      });
    }

    case Actions.update: {
      const { dossierStructure } = action.payload;

      return produce(state, draft => {
        const index = draft.dossierStructures.findIndex(({ _id }) => dossierStructure._id === _id);
        if (index !== -1) {
          draft.dossierStructures[index] = dossierStructure;
        } else {
          draft.dossierStructures.push(dossierStructure);
        }
        draft.currentDossierStructure = dossierStructure;
        draft.isLoading = false;
      });
    }

    default:
      return state;
  }
};
