import { authorityService } from 'src/services/authorityService';

export const LOAD_AUTHORITIES = '@authority/load-authorities';
export const LOAD_AUTHORITIES_FAILURE = '@authority/load-authorities-failure';

export function loadAllAuthorities() {
  return async dispatch => {
    try {
      const authorities = await authorityService.getAll();
      dispatch({
        type: LOAD_AUTHORITIES,
        payload: {
          authorities
        }
      });
    } catch (error) {
      dispatch({ type: LOAD_AUTHORITIES_FAILURE });
      throw error;
    }
  };
}
