import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { capitalCase } from 'change-case';
import { Box, FormControlLabel, IconButton, Popover, SvgIcon, Switch, TextField, Tooltip, Typography, makeStyles } from '@material-ui/core';
import merge from 'lodash/merge';
import SettingsIcon from '@material-ui/icons/Settings';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  },
  icon: {
    stroke: theme.palette.third.main,
    strokeWidth: '2px'
  }
}));

function Settings() {
  const classes = useStyles();
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState({
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    mode: settings.mode
  });
  const history = useHistory();

  const { t, i18n } = useTranslation('navigation');

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const { user } = useSelector(state => state.account);

  // store users role(s) in an array
  const userRoles = user.roles;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = async (field, value) => {
    const newValueObj = merge({}, values, { [field]: value });
    setValues(newValueObj);

    // save the changes
    saveSettings(newValueObj);

    // make sure that the start page of coresponding mode is loaded
    if (field === 'mode' && value === 'admin') history.push('/app/dashboard');
    if (field === 'mode' && value === 'user') history.push('/app/dossier');
  };

  const isAdminMode = () => userRoles.indexOf('Administrator') > -1 && userRoles.length > 1;

  // button to switch between user mode and admin mode
  const adminSwitch = (
    <Box mt={2} px={1}>
      <FormControlLabel
        control={
          <Switch
            checked={values.mode === 'admin'}
            edge="start"
            name="mode"
            onChange={event => handleChange('mode', event.target.checked ? 'admin' : 'user')}
          />
        }
        label={t('settings.admin_mode')}
      />
    </Box>
  );

  return (
    <>
      <Tooltip title="Settings">
        <IconButton color="inherit" onClick={handleOpen} ref={ref}>
          <SvgIcon >
            <SettingsIcon className={classes.icon}/>
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h4" color="textPrimary">
          {t('settings.label')}
        </Typography>
        <Box mt={2} px={1}>
          <FormControlLabel
            control={
              <Switch
                checked={values.responsiveFontSizes}
                edge="start"
                name="responsiveFontSizes"
                onChange={event => handleChange('responsiveFontSizes', event.target.checked)}
              />
            }
            label={t('settings.responsive_fonts')}
          />
        </Box>
        {isAdminMode() ? adminSwitch : null}
        <Box mt={2}>
          <TextField
            fullWidth
            label={t('settings.theme')}
            name="theme"
            onChange={event => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map(theme => (
              <option key={theme} value={theme}>
                {capitalCase(theme)}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label={t('settings.locale')}
            name="locale"
            onChange={event => {
              const newLocale = event.target.value;
              changeLanguage(newLocale);
            }}
            select
            SelectProps={{ native: true }}
            value={i18n.language}
            variant="outlined"
          >
            <option value="de">Deutsch</option>
            <option value="fr">Français</option>
            <option value="it">Italiano</option>
          </TextField>
        </Box>
      </Popover>
    </>
  );
}

export default Settings;
