import axios from 'src/utils/axios';

class UserService {
  addUser = data =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/users', data)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  loadUsers = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users')
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  loadSingleUser = userId =>
    new Promise((resolve, reject) => {
      axios
        .get(`/api/users/${userId}`)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  deleteUser = userId =>
    new Promise((resolve, reject) => {
      console.log('Delete user: ' + userId);
      axios
        .delete('/api/users/' + userId)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  updateAvatar = (userId, data) =>
    new Promise((resolve, reject) => {
      axios
        .put(`/api/users/${userId}/avatar`, data)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
}
const userService = new UserService();

export default userService;
