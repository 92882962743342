/* eslint-disable no-param-reassign */
import produce from 'immer';
import { LOAD_AUTHORITIES, LOAD_AUTHORITIES_FAILURE } from 'src/actions/authorityActions';

const initialState = {
  isAuthorityLoaded: false,
  authorities: null,
  authorityUpdatedAt: new Date()
};

const authorityReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_AUTHORITIES: {
      const { authorities } = action.payload;

      return produce(state, draft => {
        draft.authorities = authorities;
        draft.isAuthorityLoaded = true;
      });
    }
    case LOAD_AUTHORITIES_FAILURE: {
      return produce(state, draft => {
        draft.authorities = null;
        draft.isAuthorityLoaded = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default authorityReducer;
