/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOAD_SINGLE_DOSSIER,
  UPLOAD_DOCUMENT_TO_DOSSIER,
  ADD_DOSSIER_FILE,
  ADD_DOSSIER,
  DELETE_DOSSIER_FILE,
  UPDATE_DOSSIER_FILE,
  DELETE_DOSSIER_FILE_DOCUMENT,
  UPDATE_DOSSIER_STRUCTURE
} from 'src/actions/dossierActions';
import { ADD_DOSSIER_FILE_DOCUMENT } from '../actions/dossierActions';

const initialState = {
  isLoaded: false,
  dossiers: null, // all dossiers
  isDossierLoaded: false,
  dossier: null, // single dossier
  dossierUpdatedAt: new Date()
};

const dossierReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DOSSIER: {
      const { dossier } = action.payload;

      return produce(state, draft => {
        draft.dossier = dossier;
        draft.isLoaded = false;
        draft.isDossierLoaded = false;
      });
    }
    case LOAD_SINGLE_DOSSIER: {
      const { dossier } = action.payload;

      return produce(state, draft => {
        draft.dossier = dossier;
        draft.dossierUpdatedAt = new Date();
        draft.isDossierLoaded = true;
      });
    }
    case UPDATE_DOSSIER_STRUCTURE: {
      const { dossierStructure } = action.payload;

      return produce(state, draft => {
        draft.dossier.dossier_structure = dossierStructure;
        draft.dossierUpdatedAt = new Date();
        draft.isDossierLoaded = true;
      });
    }
    case UPLOAD_DOCUMENT_TO_DOSSIER: {
      const { dossier } = action.payload;

      return produce(state, draft => {
        draft.dossier = dossier;
        draft.dossierUpdatedAt = new Date();
        draft.isDossierLoaded = true;
      });
    }
    case ADD_DOSSIER_FILE: {
      const { dossier } = action.payload;

      return produce(state, draft => {
        draft.dossier = dossier;
        draft.dossierUpdatedAt = new Date();
        draft.isDossierLoaded = true;
      });
    }
    case ADD_DOSSIER_FILE_DOCUMENT: {
      const { dossier } = action.payload;

      return produce(state, draft => {
        draft.dossier = dossier;
        draft.dossierUpdatedAt = new Date();
        draft.isDossierLoaded = true;
      });
    }
    case UPDATE_DOSSIER_FILE: {
      const { dossier } = action.payload;

      return produce(state, draft => {
        draft.dossier = dossier;
        draft.dossierUpdatedAt = new Date();
        draft.isDossierLoaded = true;
      });
    }
    case DELETE_DOSSIER_FILE: {
      const { dossier } = action.payload;

      return produce(state, draft => {
        draft.dossier = dossier;
        draft.dossierUpdatedAt = new Date();
        draft.isDossierLoaded = true;
      });
    }
    case DELETE_DOSSIER_FILE_DOCUMENT: {
      const { dossier } = action.payload;

      return produce(state, draft => {
        draft.dossier = dossier;
        draft.dossierUpdatedAt = new Date();
        draft.isDossierLoaded = true;
      });
    }
    default: {
      return state;
    }
  }
};

export default dossierReducer;
